<template>
  <v-dialog v-model="open" width="1200px" @click:outside="close()" @keydown.esc="close()">
    <v-card>
      <v-card-title>Сформировать выгрузку АВР</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="contractor"
              :items="contractorList"
              placeholder="Введите наименование контрагента"
              persistent-placeholder
              @update:search-input="getContractor"
              item-value="id"
              item-text="name"
              no-data-text="Начните вводить наименование или БИН контрагента"
              return-object
              class="filters__item--autocomplete"
              item-color="#0B583F"
              outlined
              hide-details
              no-filter
            />
          </v-col>
          <v-col cols="5">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="date ? `${date[0] || ''} ~ ${date[1] || ''}` : ''"
                  label="Выберите интервал дат"
                  prepend-icon="mdi-calendar"
                  class="filters__item--date-range"
                  readonly
                  hide-details
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="resetDate()">
                  Очистить
                </v-btn>
                <v-btn text color="primary" @click="menu = false">
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="saveDate(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-btn rounded color="primary" @click="getAVR" :disabled="!available">
              Сформировать АВР
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="act.length > 0 && found">
          <v-col>
            <crm-table :headers="headers" :data="act">
              <template #last-slot>
                <div style="width: 100%; display: flex; justify-content: flex-end">
                  <v-btn color="#1e6e54" dark @click="exportExcel" :loading="exportLoading">
                    Скачать</v-btn
                  >
                </div>
              </template>
            </crm-table>
          </v-col>
        </v-row>
        <v-row v-if="act.length === 0 && found">
          <v-col class="text-center error--text" style="font-size: 18px">
            <span>По заданным фильтрам ничего не найдено</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="commission-edit-confirm-modal__actions">
        <v-btn text class="commission-edit-confirm-modal__actions--cancel" @click="close()">
          ОТМЕНИТЬ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import crmTable from '@/components/crm-table/index.vue';
import XLSX from 'xlsx';

export default {
  name: 'avr',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    crmTable,
  },
  data() {
    return {
      act: [],
      headers: [
        {
          value: 'name',
          sortable: false,
          text: 'Контрагент',
          type: 'defaultItem',
        },
        {
          value: 'created',
          sortable: true,
          text: 'Дата',
          type: 'defaultItem',
        },
        {
          value: 'orderId',
          sortable: true,
          text: '№ Заказа',
          type: 'defaultItem',
        },
        {
          value: 'income',
          sortable: false,
          text: 'Сумма дохода',
          type: 'defaultItem',
        },
      ],
      found: false,
      config: {
        search: false,
        pageable: false,
      },
      contractor: {},
      contractorList: [],
      timeoutId: null,
      menu: false,
      date: null,
      url: 'PaymentOrder/Companies',
      options: {
        page: 1,
        size: 10,
      },
      totalElements: 0,
      totalPages: 0,
      exportLoading: false,
      getAll: {
        id: 0,
        name: 'Выбрать все',
      },
    };
  },
  created() {
    this.contractorList.push(this.getAll);
  },
  computed: {
    available() {
      if (Object.keys(this.contractor).length === 0 || !this.date) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getContractor(val) {
      if (val) {
        this.contractorList = [];
        this.contractorList.push(this.getAll);
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(async () => {
          const response = await this.$billing.get(`PaymentOrder/Companies?search.value=${val}`);
          const contractorList = response.data;
          this.$nextTick(() => {
            this.contractorList = this.contractorList.concat(contractorList);
          });
        }, 500);
      }
    },
    saveDate(dates) {
      let checkedDates = [...dates];
      if (this.$moment(dates[0]).isAfter(dates[1])) {
        checkedDates = [...dates].reverse();
      }
      this.date = checkedDates;
      this.menu = false;
    },
    resetDate() {
      this.date = null;
    },
    close() {
      this.act = [];
      this.date = null;
      this.contractor = {};
      this.found = false;
      this.$emit('close');
    },
    getAVR() {
      this.$loading(true);
      const params = {
        dateUntil: this.date[1],
        dateFrom: this.date[0],
      };
      if (this.contractor.id !== 0) {
        params.companyId = this.contractor.id;
      }
      this.$billing
        .get('PaymentOrder/Act', {
          params,
        })
        .then((response) => {
          this.totalElements = response.recordsFiltered;
          this.totalPages = Math.ceil(response.recordsFiltered / 10);
          this.act = [...response.data].map((item) => ({
            ...item,
            created: this.getFormattedDate(item.created),
          }));
          this.found = true;
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    getFormattedDate(date) {
      if (!this.$moment(date).isValid()) return 'Нет данных';
      return this.$moment(date).format('DD.MM.YYYY HH:mm');
    },
    async exportExcel() {
      this.exportLoading = true;
      const workBook = XLSX.utils.book_new();
      if (this.act.length > 0) {
        const data = this.act.map((i) => ({
          Контрагент: i.name,
          'Дата и время транзакции': i.created.split(' ')[0],
          '№ Заказа': i.orderId,
          'Сумма дохода': i.income,
        }));
        const workSheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, workSheet);
        const exportFileName = `АВР_${this.contractor.name}_${this.date[1]}.xls`;
        XLSX.writeFile(workBook, exportFileName);
      }
      this.exportLoading = false;
    },
  },
};
</script>
<style lang="scss">
.commission-v-dialog {
  &.v-dialog {
    border-radius: 0;
  }
}
.commission-edit-confirm-modal {
  border-radius: 0 !important;
  &__actions {
    &.v-card__actions {
      padding: 12px 20px !important;
    }

    .v-btn__content {
      color: #007450;
    }
    .v-btn {
      border-radius: 18px;
    }
  }
}
.filters {
  &__item {
    &--autocomplete {
      width: 100%;
    }
  }
}
</style>
